import React from "react";
import {
  SEO,
  PageLayout,
  Background,
  InternalLink,
  CallToActionBanner,
  Call,
  Content,
  ImageCollection,
  Reviews,
  Map,
} from "@bluefin/components";
import { Header, Button } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class IndexPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout
          hero={
            <Background columns={2} stackable={true} className={"home-hero"}>
              <Background.Color color={"white"} textAlign={"left"}>
                <Header as={"div"} className={"h6"}>
                  Welcome To
                </Header>
                <Header as={"h1"}>{fishermanBusiness.name}</Header>
                <Button
                  primary={true}
                  size={"large"}
                  to={"/menu/"}
                  as={InternalLink}
                >
                  View Menu
                </Button>
              </Background.Color>
              <Background.Image
                images={getComponentFiles({
                  components: fishermanBusinessWebsitePage.components,
                  componentName: "HeroElement",
                })}
              />
            </Background>
          }
          subfooter={false}
          className={"home-page"}
        >
          <CallToActionBanner stackable={true}>
            <CallToActionBanner.CTA>
              <Call
                phoneNumber={"(510) 957-5775"}
                as={"button"}
                buttonColor={"primary"}
              />
              <Call
                phoneNumber={"(510) 812-3915"}
                as={"button"}
                buttonColor={"primary"}
              />
            </CallToActionBanner.CTA>
          </CallToActionBanner>
          <Content stackable={true}>
            <Content.Image
              src={getComponentFiles({
                components: fishermanBusinessWebsitePage.components,
                componentName: "Content.Image",
                componentIdentifier: "about",
                numToSelect: 1,
              })}
            />
            <Content.Markup truncate={true} paragraphs={2}>
              {fishermanBusiness.aboutMarkdown}
            </Content.Markup>
          </Content>
          <ImageCollection
            images={getComponentFiles({
              components: fishermanBusinessWebsitePage.components,
              componentName: "ImageCollection",
              numToSelect: 2,
            })}
            as={"gallery"}
            centered={true}
            cutUnevenRows={true}
            smartImageGrouping={false}
            disable={"none"}
            header={""}
            numberOfRowDisplayItems={2}
          />
          <div className={"reviews-container"}>
            <Reviews
              reviews={fishermanBusiness.reviews.slice(0, 3)}
              as={"card"}
              buttonBasic={false}
              buttonSize={"medium"}
              centerHeader={true}
              cardInlinePhoto={"left"}
              header={"Reviews"}
              imageCircular={false}
              imageSize={"small"}
              itemButtonFloat={false}
              itemCenterContent={false}
              itemsDivided={false}
              itemsPerRow={3}
              linkAs={"none"}
              onlyEvenRows={true}
              showImage={true}
              verticalItemAlign={"left"}
            />
            <Button
              to={"/about/"}
              primary={true}
              size={"large"}
              as={InternalLink}
            >
              See More
            </Button>
          </div>
          <Map
            address={{
              street: fishermanBusiness.primaryLocation.street,
              city: fishermanBusiness.primaryLocation.city,
              state: fishermanBusiness.primaryLocation.state,
              zip: fishermanBusiness.primaryLocation.zipCode,
            }}
          />
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      primaryLocation {
        phoneNumber
        street
        city
        state
        zipCode
      }
      aboutMarkdown
      reviews {
        author
        text
        link
      }
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Home" }) {
      title
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
